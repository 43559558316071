<template>
  <div>
    <p class="mb-2">{{paymentMethodLabel}}</p>
    <el-radio-group v-model="paymentSource">
      <el-radio v-for="(label, val) in paymentSources" :key="val" name="payment_source" :label="val">{{ label }}</el-radio>
    </el-radio-group>
    <el-input
    class="mb-2"
      type="textarea"
      rows="4"
      v-model="note"
      id="payment-source-note"
      :placeholder="paymentMethodNoteLabel"
    />
  </div>
</template>

<script>
import { RadioGroup, Radio } from "element-ui";
export default {
  name: "PaymentValidationForm",
  components: {
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },

  props: {
    paymentMethodNoteLabel: {
      type: String, 
      default: null, 
    },
    paymentMethodLabel: {
      type: String, 
      default: null, 
    },
    defaultPaymentSource: {
      type: String, 
      default: null, 
    },
    paymentSources: {
      type: Object,
      required:false,
      default: () => {}, 
    },
  },

  data() {
    return {
      paymentSource: this.defaultPaymentSource,
      note: null,
    };
  },
};
</script>
